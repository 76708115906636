<i18n locale="th" lang="yaml" src="@i18n/ticket/ticket.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
ticket.field.event.date.help : "วันที่พบปัญหา เพื่อใช้ในการตรวจสอบ Log"
ticket.field.event.time.help : "เวลาที่พบโดยประมาณ"
ticket.field.report_by.placeholder : "เลือกผู้รับแจ้ง"
ticket.field.report_by.help : "ผู้รับแจ้ง หรือ สามารถติดต่อลูกค้าเพื่อขอข้อมูลเพิ่มได้"
ticket.field.location_type.help : "เครื่อง ณ ตอนนี้อยู่ที่"
ticket.field.problem_type.help : "สอบถามทั่วไป หรือ ต้องการให้ช่วยประเมินการเคลม"
ticket.field.ticket_types.help : "ปัญหาที่พบ เจอที่ส่วนไหน ระบุคร่าว ๆ เพื่อช่วยในการแยกแยะปัญหาเบื้องต้น"
ticket.field.topic.help : "หัวข้อในการแจ้งปัญหาอย่างย่อ เช่น เข็มทิศมีปัญหาตอนทำงานไป 3 รอบ"
ticket.field.search_tags.help : "ตัวช่วยในการค้นหา เช่น เลข Jira หรือ อาการที่ share ร่วมกัน"

ticket.field.general_ticket.help : "ปัญหาทั่วไป ที่ไม่เกิดขึ้นกับ Drone แบบเจาะจง"

ticket.field.search_tags.autocomplete : "กล้อง,กลับบ้านเอง,ตัวส่งสัญญาณภาพ,ปั้มน้ำ,รีโมทหลุด,เข็มทิศ,เรดาร์,เลเซอร์,ใบพัด,bec,electronics,pm08,checkup"

ticket.field.drone.help : "S/N ของ Drone ถ้ามีการตั้งค่าแล้วจะไม่สามารถเปลี่ยนแปลงได้อีก"
ticket.field.drone.placeholder : "S/N เช่น 2000100010"
</i18n>
<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">

		<a-form-item v-if="isGeneralTicket" v-bind="wrapperOnlyLayout">
			{{$t('ticket.field.general_ticket.help')}}
		</a-form-item>
		<a-form-item v-else :label="$t('ticket.field.drone')">
			{{cDroneObj.name}}
		</a-form-item>

		<a-form-item v-if="isGeneralTicket && isUpdate" :label="$t('ticket.field.drone')"
			:extra="$t('ticket.field.drone.help')">
			<a-input v-decorator="['drone_serial_no']" :placeholder="$t('ticket.field.drone.placeholder')" />
		</a-form-item>

		<a-form-item :label="$t('ticket.field.report_by')"
			:extra="$t('ticket.field.report_by.help')"
			:required="true">
			<UserSelect
				v-decorator="['report_by_id' ,
					{
						rules: [
							{ validator: validateReportByData },
						],
					}]"
				:placeholder="$t('ticket.field.report_by.placeholder')"
				select-mode="ticket_reporter"/>
		</a-form-item>

		<hr />

		<a-form-item :label="$t('ticket.field.event.date')"
			:extra="$t('ticket.field.event.date.help')">
			<MyDatePicker
			v-decorator="['event_datetime',
					{
						rules: [
							{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.event.date') },
						] ,
					}]"
			format="DD MMMM YYYY"/>
		</a-form-item>
		<a-form-item :label="$t('ticket.field.event.time')"
			:extra="$t('ticket.field.event.time.help')">
			<a-time-picker
				v-decorator="['event_time',
					{
						rules: [
							{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.event.time') },
						] ,
					}]"
				input-read-only
				format="HH:mm"
				value-format="HH:mm"/>
		</a-form-item>

		<a-form-item :label="$t('ticket.field.problem_type')"
			:extra="$t('ticket.field.problem_type.help')">
			<a-radio-group v-decorator="[
				'problem_type' ,
				{
					rules: [
						{ required: true, message: $tt('validate.required','ticket.field.problem_type') },
					],
					initialValue: 'general',
				},
				]"
				:options="problemTypeOptions"/>
		</a-form-item>

		<a-form-item :label="$t('ticket.field.ticket_types')"
			:extra="$t('ticket.field.ticket_types.help')">
			<a-checkbox-group
				v-decorator="['ticket_types',{
						rules: [
							{ required: true ,whitespace: true ,message: $tt('validate.required','ticket.field.ticket_types'), type: 'array'  },
						] ,
						initialValue: ['hardware'],
				}]"
				:options="ticketTypeOptions"/>
		</a-form-item>



		<a-form-item v-show="!isGeneralTicket"
			:label="$t('ticket.field.location_type')"
			:extra="$t('ticket.field.location_type.help')">
			<a-radio-group v-decorator="[
				'location_type' ,
				{
					rules: [
						{ required: true, message: $tt('validate.required','ticket.field.location_type') },
					],
					initialValue: 'customer',
				},
				]"
				:options="locationTypeOptions"/>
		</a-form-item>

		<hr />

		<a-form-item :label="$t('ticket.field.topic')"
			:extra="$t('ticket.field.topic.help')">
			<a-textarea
				v-decorator="[
					'topic' ,
					{
						rules: [
							{ required: true, message: $tt('validate.required','ticket.field.topic') },
						],
					},
				]"
				:auto-size="{ minRows: 2,maxRows: 5 }"/>
		</a-form-item>

		<a-form-item v-if="isCreate" :label="$t('ticket.field.description')"
			:required="true"
			:validate-status="commentValidate.status"
			:help="commentValidate.message">
			<TicketCommentInput
				ref="commentInputRef"
				@change="handleComment"/>
		</a-form-item>

		<a-form-item :label="$t('ticket.field.search_tags')"
			:extra="$t('ticket.field.search_tags.help')">
			<MyTagsInputAutoComplete
				v-model="searchTags"
				:auto-complete-list="cTagsAutoComplete"
				tag-css-class="mytag-info"/>
		</a-form-item>


		<hr />

		<a-form-item v-bind="wrapperOnlyLayout">
			<slot name="submitSlot" :formLoading="loading">
				<a-button
					:loading="loading"
					type="info"
					html-type="submit"
					class="btn-submit"
					size="large">
					{{$t('common.save')}}
				</a-button>
			</slot>

		</a-form-item>
	</a-form>
</template>
<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'

import {TimePicker,Radio,Checkbox} from "ant-design-vue"

import UserSelect from "@components/user/UserSelect.vue"
import MyDatePicker from '@components/input/MyDatePicker.vue'
import MyTagsInputAutoComplete from "@components/input/MyTagsInputAutoComplete.vue"
import TicketCommentInput from "@components/ticket/TicketCommentInput.vue"

import {updateFieldsValue,assignFieldErrors,getEnumSelectOptions} from "@utils/formUtil"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
import ModelError from '@utils/errors/ModelError'
import _get from "lodash/get"
import _join from "lodash/join"

export default {
	components : {
		MyDatePicker, MyTagsInputAutoComplete,
		UserSelect , TicketCommentInput ,
		"a-time-picker" : TimePicker,
		"a-radio-group" : Radio.Group ,
		"a-checkbox-group" : Checkbox.Group,
	} ,
	mixins : [HasAntdFormMixin,LoginInfoMixin] ,
	props : {
		mode : {
			type : String,
			default : 'create'
			// create-with-drone, create-general, update
		} ,
		loading : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			isGeneralTicket : false,
			droneData : {} ,
			commentData : {} ,
			commentValidate : {
				status : '',
				message : undefined
			} ,
			searchTags : []
		}
	} ,
	computed : {
		isCreate() {
			return this.mode == 'create-general' || this.mode == 'create-with-drone'
		} ,
		isUpdate() {
			return this.mode == 'update'
		} ,
		cDroneObj() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		cTagsAutoComplete() {
			return this.$t('ticket.field.search_tags.autocomplete').split(',')
		} ,
		ticketTypeOptions() {
			const options = ['hardware','software','part','others']
			return getEnumSelectOptions(this,'ticket.ticket_type',options)
		} ,
		locationTypeOptions() {
			return getEnumSelectOptions(this,'ticket.location_type',['customer','service','hgr'])
		} ,
		problemTypeOptions() {
			return getEnumSelectOptions(this,'ticket.problem_type',['general','consult'])
		} ,
	} ,
	mounted() {
		if (this.mode == 'create-general')
			this.isGeneralTicket = true
		if (this.isCreate) {
			this.formObj.setFieldsValue({
				report_by_id : {
					user : this.currentUser,
					company : this.currentCompany,
				}
			})
		}
	} ,
	methods : {
		validateReportByData(rule,value,callback) {
			if (!_get(value,'user.id')) {
				callback(this.$tt('validate.required','ticket.field.report_by') )
			} else {
				callback()
			}
		} ,
		validateComment() {
			if (!(this.isCreate)) {
				return
			}
			if ( !_isStringEmpty(this.commentData.detail_text) ||
				(this.commentData.files && this.commentData.files.length > 0) ||
				(this.commentData.gallery && this.commentData.gallery.length >0 )) {
					this.commentValidate.status = ''
					this.commentValidate.message = undefined
			} else {
				this.commentValidate.status = 'error'
				this.commentValidate.message = this.$tt('validate.required','ticket.field.description')
			}
		} ,

		handleComment(event) {
			this.commentData = {...event.data};
			this.validateComment()
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				this.validateComment()
				if (err || this.commentValidate.status == 'error')
				 	return;

				const ticket = {
					report_by_id : values.report_by_id.user.id ,
					event_datetime : values.event_datetime + ' ' + values.event_time+":00" ,
					location_type : values.location_type,
					topic : values.topic ,
					problem_type : values.problem_type ,
					ticket_types : _join(values.ticket_types,','),
					search_tags : JSON.stringify(this.searchTags) ,
					drone_serial_no : values.drone_serial_no,
				}
				let formData;
				if (this.isCreate) {
					formData = {
						ticket  : {
							...ticket,
							drone_id : !this.isGeneralTicket && values.drone_id ? values.drone_id.drone.id : null
						} ,
						comment : {
							detail_rich : this.commentData.detail,
							files : this.commentData.files ,
							galleries : this.commentData.gallery,
						}
					}
				} else {
					formData = ticket;
				}
				this.$emit('submit',formData)
			})
		} ,
		// Provide External Call
		updateDroneData(droneData) {
			this.droneData = {...droneData}
		} ,
		updateTicketForm(ticket,comment=null) {
			this.droneData = {...ticket.droneData}
			let eventDate ,eventTime;
			if (ticket.event_datetime) {
				const date = this.$dayjs(ticket.event_datetime)
				eventDate = date.format('YYYY-MM-DD')
				eventTime = date.format('HH:mm')
			}
			if (!ticket.drone_id) {
				this.isGeneralTicket = true
			}
			updateFieldsValue(this.formObj,{
				...ticket,
				report_by_id : {
					user : ticket.reportByData.user,
					company : ticket.reportByData.company,
				} ,
				event_datetime : eventDate,
				event_time : eventTime ,
				ticket_types : ticket.ticket_types.split(',')
			})
			if (comment) {
				this.$nextTick(()=> {
					if (this.$refs.commentInputRef) {
						this.$refs.commentInputRef.updateComment(comment)
					}
				})
			}
			this.searchTags = ticket.search_tags
		} ,
		formErrors(error) {
			if (error instanceof ModelError) {
				const ticketErr = error.getFieldErrorsByModelKey('ticket')
				if (ticketErr) {
					assignFieldErrors(this.formObj,ticketErr.field_errors)
				}
				if (error.getFieldErrorsByModelKey('comment')) {
					this.commentValidate.status = 'error'
					this.commentValidate.message = this.$tt('validate.invalid','ticket.field.comment')
				}
				this.displayValidateErrorMessage()
			} else {
				this.formSubmitErrors(error)
			}
		}
	}
}
</script>
