<i18n locale="th" lang="yaml" >
mytags.add.label : "เพิ่ม"
</i18n>

<template>
	<div class="mytags">
		<a-tag v-for="tag in value" :key="tag" :closable="!readOnly" :class="tagCssClass" @close="handleClose($event,tag)">
			{{tag}}
		</a-tag>
		<template v-if="!readOnly">
			<a-auto-complete
				v-if="inputVisible"
				ref="input"
				class="mytag-autocomplete"
				size="small"
				:default-active-first-option="false"
				:data-source="cOptions"
				:style="{ width: '100px' }"
				:value="inputValue"
				@change="handleInputChange"
				@blur="handleInputBlur"
				@keydown.tab.native="handleInputConfirm"
				@keydown.esc.native="handleInputBlur"
				@keypress.enter.native="handleInputConfirm"
				@select="handleInputSelect"
				@search="handleInputSearch"
				@dropdownVisibleChange="handleDropdownVisible">
				<a-input slot="input" ref="textInput" />
			</a-auto-complete>
			<a-tag v-else ref="newTags" class="mytags-add"
				tabindex="0"
				@click="showInput" @focus="showInput">
				<a-icon type="plus" /> {{cAddLabel}}
			</a-tag>
		</template>
	</div>
</template>

<script>
import {Tag,AutoComplete} from "ant-design-vue"

import { isStringEmpty } from '@utils/stringUtil'
export default {
	components : {
		"a-tag" : Tag ,	"a-auto-complete" : AutoComplete,
	} ,
	model : {
		prop: 'value',
    event: 'change'
	} ,
	props : {
		value : {
			type : Array,
			default : ()=>[]
		} ,
		addLabel : {
			type : String,
			default : undefined
		} ,
		tagCssClass : {
			type : String ,
			default : ''
		} ,
		readOnly : {
			type : Boolean ,
			default : false
		} ,
		autoCompleteList : {
			type : Array,
			default : () => []
		}
	} ,
	data() {
		return {
			inputVisible : false ,
			inputValue : '',
			fromEnter : false,
			inputSearch : '',
			dropdownOpen : false
		}
	} ,
	computed : {
		cAddLabel(){
			return isStringEmpty(this.addLabel) ? this.$t('mytags.add.label') : this.addLabel
		} ,
		cOptions() {
			return this.autoCompleteList.filter((s) => s.startsWith(this.inputSearch) && this.value.indexOf(s) === -1)
		}
	} ,
	methods : {
		showInput() {
			this.inputVisible = true
			this.$nextTick(function() {
        this.$refs.input.focus();
      });
		} ,
		handleInputSearch(search) {
			this.inputSearch = search
		} ,
		handleClose(event,removedTag) {
			const tags = this.value.filter(tag => tag !== removedTag);
			this.$emit("change",tags)
		} ,
		handleInputChange(e) {
			this.inputValue = e;
		} ,
		handleInputBlur(e) {
			this.inputValue = ''
			this.inputSearch = ''
			if (this.fromEnter) {
				this.fromEnter = false
				this.$nextTick(() => {
					if (this.inputVisible) {
						this.$refs.input.focus()
					}
				})
			} else {
				this.inputVisible = false
			}
		} ,
		handleInputConfirm(e) {
			if (e && e.code == 'Tab') {
				e.preventDefault()
				return
			}
			if (this.inputValue) {
				this.fromEnter = true
				if (this.value.indexOf(this.inputValue) === -1) {
					this.$emit('change',[...this.value,this.inputValue])
				}
				this.inputValue = ''
				this.inputSearch = ''
			}
			this.handleInputBlur()
			if (e)
				e.preventDefault()
		} ,
		handleInputSelect(value,options) {
			this.inputValue = value
			this.handleInputConfirm(null)
		} ,
		handleDropdownVisible(open) {
			this.dropdownOpen = open
		}
	}
}
</script>

<style lang="less" scoped>
.mytags {
	display : inline-block;
}
.mytags-add {
	background : @white;
	border-style: dashed;
	min-width : 90px;
	text-align : center;
	cursor: pointer;
}

</style>
