<i18n locale="th" lang="yaml" >
user.field.company : "บริษัท"
</i18n>

<template>
	<div class="mycard-select-card">
		<div v-if="user.user_role">
			<span class="card-title">{{user.username}}</span>
			({{$tenum('user_role',`${user.user_role}`)}})
		</div>
		<div>
			<label>{{$t('user.field.company')}}</label> : {{company.name}}
		</div>
	</div>
</template>

<script>
export default {
	props : {
		user : {
			type : null,
			default : () => []
		} ,
		company : {
			type : null,
			default : () => []
		} ,
	}
}
</script>
