<i18n locale="th" lang="yaml" >
user_select.show_all : "แสดงทุกบริษัท"
</i18n>

<template>
	<MySelect
		v-bind="$attrs"
		class="user-select"
		:loading="searchLoading"
		:pagination="pagination"
		option-label-prop="label"
		:value="sValue"
		@change="handleChange"
		@clear-search="clearSearchUser"
		@search="searchUser">
		<div v-if="showCustom" slot="customTop">
      <div
        style="padding: 4px 8px; cursor: pointer;"
				@mousedown="e => e.preventDefault()">
        <a-checkbox v-model="showAll" @change="changeShowAll" />
				<span style="margin-left:2px">{{$t('user_select.show_all')}}</span>
      </div>
			<a-divider style="margin: 4px 0;" />
    </div>
		<a-select-option v-for="userData in userDataList" :key="userData.id"
			:value="userData.id"
			:label="userData.user.username">
			<UserSelectCard
				:user="userData.user"
				:company="userData.company"/>
		</a-select-option>
	</MySelect>
</template>

<script>
import MySelect from "@components/input/MySelect.vue"
import UserSelectCard from "./UserSelectCard.vue"
import {Select,Checkbox,Divider} from "ant-design-vue"
import axios from "axios"
import _get from "lodash/get"
import ApiError from "@utils/errors/ApiError"
import { fulfillUser ,emptyUser } from '@utils/objectUtil'
export default {
	components : {
		MySelect,
		"a-checkbox" : Checkbox, "a-divider" : Divider,
		"a-select-option" : Select.Option, UserSelectCard ,
	} ,
	model: {
    prop: 'value',
    event: 'change'
	},
	props : {
		showCustom : {
			type : Boolean,
			default : false,
		} ,
		selectMode : {
			type: null,
			default : undefined
		} ,
		companyId : {
			type : null,
			default : undefined,
		} ,
		value : {
			type : null,
			default : undefined
		} ,
	} ,
	data(){
		return {
			searchLoading : false ,
			userDataList : [],
			dataBucket : [] ,

			pagination : {
				pageSize : 8 ,
				current : 1,
				total : 0,
			} ,
			showAll : false,
		}
	} ,

	computed: {
		sValue() {
			return _get(this.value,'user.id')
		}
	} ,
	methods : {
		changeShowAll(e) {
			this.searchUser({search:'',page:1})
			e.preventDefault()
		} ,
		clearSearchUser() {
			this.pagination.current = 1;
			this.pagination.total = 0;
			this.userDataList=[]
			this.dataBucket=[]
		} ,
		handleChange(value) {
			let selectedUser;
			if (value) {
				selectedUser = {...this.userDataList.find((user) => user.id==value)}
			} else {
				selectedUser = emptyUser()
			}
			this.$emit('change',selectedUser)
		} ,
		searchUser(searchEvent) {
			this.searchLoading = true
			const mode = (this.selectMode)? this.selectMode : this.showAll ? 'all' : 'company'
			const searchParamsData = {
				search : {
					page_size : this.pagination.pageSize ,
					current_page : searchEvent.page,
				} ,
				filter : {
					keyword : searchEvent.search ,
					status : 'active' ,
					mode ,
					company_id : this.companyId
				}
			}
			axios.get("/api/users/search",{params : searchParamsData}).then((response)=>{
			 	const page = response.data.data.pagination;
				this.dataBucket = response.data.data.bucket;
				this.userDataList = response.data.data.users.map((user) => {
					return fulfillUser(user,this.dataBucket)
				})

			 	this.pagination.current = searchEvent.page;
			 	this.pagination.total = page.total;
			 }).catch((error)=>{
			 	this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			 }).finally(()=>{
			 	this.searchLoading = false
			 })
		}
	}
}
</script>
<style lang="less" scoped>
.user-select {
	width : 100%;
	min-width : 150px;
	max-width : 250px;
}
</style>
